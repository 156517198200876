<template>
    <div class="mb-5 pb-5 px-0">
        <div class="my-10 pb-10 d-flex justify-content-between">
            <div>
                <h1>
                    Detalles de producto
                    <strong class="text-primary">
                        {{ name }}
                    </strong>
                </h1>
                <strong> SKU {{ productComponent.sku }} </strong>
            </div>
            <div class="d-flex align-items-center">
                <div>
                    <vs-button circle icon floating @click="getBaseItem">
                        <reload-icon />
                    </vs-button>
                </div>
            </div>
        </div>

        <div v-if="id" class="my-3">
            <vs-navbar
                id="tabs-products"
                v-model="activeTab"
                center-collapsed
                not-line
                class="position-static"
            >
                <template #left>
                    <vs-navbar-item id="home" class="">
                        <router-link
                            to="/app/products"
                            class="rounded-circle bg-info d-flex justify-content-center align-items-center p-2"
                        >
                            <span>🏠</span>
                        </router-link>
                    </vs-navbar-item>
                    <vs-navbar-item
                        id="general"
                        :active="['general', 'home'].includes(activeTab)"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none">
                                General&nbsp;
                            </span>
                            <span>⚙️</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        id="references"
                        :active="activeTab === 'references'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none">
                                Referencias&nbsp;
                            </span>
                            <span>🛍️</span>
                        </div>
                    </vs-navbar-item>

                    <vs-navbar-item
                        id="routes"
                        :active="activeTab === 'routes'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none">
                                Líneas y Categorías&nbsp;
                            </span>
                            <span>🏷️</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="$enabledEcommerceOptions"
                        id="files"
                        :active="activeTab === 'files'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none">
                                Archivos adjuntos&nbsp;
                            </span>
                            <span>🗂</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="$enabledEcommerceOptions"
                        id="extraOptions"
                        :active="activeTab === 'extraOptions'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none">
                                Opciones Extra&nbsp;
                            </span>
                            <span>🎲</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="$enabledEcommerceOptions"
                        id="delivery"
                        :active="activeTab === 'delivery'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none">
                                Entrega&nbsp;
                            </span>
                            <span>🚚</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        v-if="$enabledEcommerceOptions"
                        id="seo"
                        :active="activeTab === 'seo'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none"> SEO&nbsp; </span>
                            <span>🐱‍💻</span>
                        </div>
                    </vs-navbar-item>
                    <vs-navbar-item
                        id="advance"
                        :active="activeTab === 'advance'"
                        class="px-2 px-md-3"
                    >
                        <div class="d-flex">
                            <span class="d-lg-block d-none">
                                Avanzado&nbsp;
                            </span>
                            <span>🤖</span>
                        </div>
                    </vs-navbar-item>
                </template>
                <template #right>
                    <div class="d-none d-md-block">
                        <vs-switch
                            v-if="$ability.hasScope('product:Update')"
                            v-model="enabledUpdate"
                            :class="
                                enabledUpdate ? 'bg-primary' : 'bg-secondary'
                            "
                        >
                            🖊
                        </vs-switch>
                    </div>
                </template>
            </vs-navbar>
        </div>

        <div
            v-if="$ability.hasScope('product:Update') && id"
            class="d-flex justify-content-center"
        >
            <div class="d-block d-md-none my-3">
                <vs-switch
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </div>
        </div>

        <div v-show="['general', 'home'].includes(activeTab)" id="generalTab">
            <div id="general-title" class="d-flex flex-wrap">
                <div class="d-flex flex-column">
                    <h2>Características generales</h2>
                    <span>
                        Determina las caracteríscass principales del producto.
                        El precio del producto es un reflejo del precio de la
                        referencia por defecto.
                    </span>
                </div>
            </div>

            <div v-if="id" class="d-flex flex-wrap">
                <div
                    v-if="$ability.hasScope('product:Publish')"
                    class="col-12 col-md-6 mt-5 d-flex justify-content-center align-items-center"
                >
                    <active-selector
                        v-model="productComponent.active"
                        :enabled="enabledUpdate"
                        :weight="parseInt(productComponent.weight)"
                        on-label="Publicado"
                        off-label="Oculto"
                        @change="validateTogglePublish"
                    />
                </div>

                <div
                    class="col-12 col-md-6 mt-5 p-0 d-flex justify-content-around align-items-center"
                >
                    <small class="text-left">
                        📆💾 {{ productComponent.createdAt | date }}
                    </small>
                    <small class="text-left">
                        📆✏ {{ productComponent.updatedAt | date }}
                    </small>
                </div>
            </div>

            <div class="d-flex flex-wrap mt-3">
                <div class="col-12 mt-5">
                    <vs-input
                        v-model="productComponent.name"
                        border
                        shadow
                        :state="stateEnabled"
                        icon
                        label="Nombre"
                        class="opacity-false"
                        :disabled="disabledWithGlobal"
                        @blur="setSlug"
                        @click-icon="openProduction('')"
                    >
                        <template
                            v-if="!isValidName & introValidators.name"
                            #message-danger
                        >
                            Nombre invalido
                        </template>
                        <template v-if="productComponent.active" #icon>
                            🚀
                        </template>
                    </vs-input>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="productComponent.slug"
                        border
                        shadow
                        :state="stateEnabled"
                        icon
                        label="Slug"
                        :disabled="disabledWithGlobal"
                        class="opacity-false"
                        @click-icon="setSlug"
                        @blur="introValidators.slug = true"
                    >
                        <template v-if="id" #icon> 🧾 </template>
                        <template
                            v-if="!isValidSlug & introValidators.slug"
                            #message-danger
                        >
                            Slug invalido
                        </template>
                    </vs-input>
                </div>
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="productComponent.sku"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Sku"
                        class="opacity-false"
                        :disabled="disabledWithGlobal"
                    />
                </div>
                <div class="col-12 mt-5">
                    <vs-input
                        v-model="productComponent.shortDescription"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Descripción"
                        class="opacity-false"
                        :disabled="disabledWithGlobal"
                        @blur="introValidators.shortDescription = true"
                    >
                        <template
                            v-if="
                                !isValidShortDescription &
                                introValidators.shortDescription
                            "
                            #message-danger
                        >
                            Descripción invalida. Debe tener mínimo 1 caracter
                            máximo 255
                        </template></vs-input
                    >
                </div>
            </div>

            <div v-if="$enabledEcommerceOptions" class="d-flex flex-wrap mt-3">
                <div class="col-12 mx-auto px-3">
                    <label for="description">Contenido</label>
                    <markdown-editor
                        v-model="productComponent.description"
                        :enabled="enabledWithGlobal"
                    />
                </div>
            </div>

            <div v-if="loadProduct || !id" class="d-flex flex-wrap">
                <div class="col-12 col-md-6 mt-5">
                    <brand-selector
                        v-model="productComponent.brand"
                        :enabled="enabledWithGlobal"
                    />
                </div>

                <fragment v-if="$enabledEcommerceOptions">
                    <div class="col-12 col-md-6 mt-5">
                        <product-type-selector
                            v-if="!isLoading"
                            v-model="productComponent.type"
                            :enabled="enabledWithGlobal"
                        />
                    </div>
                    <div class="col-12 col-md-6 mt-5">
                        <keywords-manage
                            v-model="productComponent.keywords"
                            :base="$data.$keywordsBase"
                            label="Palabras clave"
                            :enabled="enabledUpdate"
                        />
                    </div>

                    <div class="col-12 col-md-6 mt-5">
                        <array-string-selector
                            v-model="productComponent.tags"
                            :enabled="enabledWithGlobal"
                            label="Etiqueta"
                        />
                    </div>

                    <div class="col-12 col-md-6 mt-5">
                        <options-selector
                            v-model="productComponent.targetGroups"
                            :enabled="enabledWithGlobal"
                            :base="Object.keys($data.$targetGroupsCodes)"
                            label="Géneros"
                        />
                    </div>

                    <div class="col-12 col-md-6 mt-5">
                        <vs-input
                            v-model="productComponent.madeIn"
                            border
                            shadow
                            :state="stateEnabled"
                            class="opacity-false"
                            label="Hecho en"
                            :disabled="disabledWithGlobal"
                        />
                    </div>
                </fragment>

                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="productComponent.taxRate"
                        border
                        shadow
                        :state="stateEnabled"
                        label="Porcentaje de impuestos aplicados"
                        class="opacity-false"
                        :disabled="disabledWithGlobal"
                    />
                </div>
            </div>

            <div v-if="$enabledEcommerceOptions" class="d-flex flex-wrap">
                <h5 class="p-0 mt-4">Características</h5>
                <feature-manage
                    v-model="productComponent.features"
                    :enabled="enabledWithGlobal"
                />
            </div>

            <div
                v-if="enabledWithGlobal"
                class="mt-3 d-flex justify-content-center"
            >
                <vs-button
                    v-if="!id"
                    size="large"
                    @click="createProductFastComponent"
                >
                    Crear producto
                </vs-button>
                <vs-button v-else size="large" @click="updateProductComponent">
                    Actualizar producto
                </vs-button>
            </div>
        </div>

        <div v-if="activeTab === 'references'" id="referencesTab">
            <div id="title-references" class="d-flex flex-wrap">
                <div class="d-flex flex-column">
                    <h2>Referencias del producto</h2>
                    <span>
                        Las referencias representa cada una de las combinaciones
                        de color y tamaño que tenga el producto. Puede tener un
                        precio diferente al producto base y un stock diferente
                        también.
                    </span>
                </div>
            </div>

            <div v-if="loadProduct && !isLoading" class="d-flex flex-wrap mt-4">
                <reference-manage
                    v-for="(reference, index) in productComponent.references"
                    :key="index"
                    :product="{ name: productComponent.name }"
                    :reference="reference"
                    :open-reference-id="openReferenceId"
                    :default-reference-id="defaultReferenceId"
                    :enabled="enabledUpdate || reference.id === 0"
                    @changeActive="changeActiveQuery($event)"
                    @remove="removeReference($event)"
                    @update="changeReference($event)"
                    @created="createdReference($event)"
                    @goToReference="openProduction($event)"
                    @setDefault="setDefaultReferenceComponent($event)"
                />
                <div class="w-100 d-flex justify-content-center mt-5">
                    <vs-tooltip
                        v-if="
                            hasGlobalScope &&
                            $ability.hasScope('reference:Create') &
                                enabledUpdate
                        "
                        border
                    >
                        <button
                            class="d-flex justify-content-center align-items-center btn border border-secondary p-4"
                            @click="createNewReference"
                        >
                            ➕
                        </button>
                        <template #tooltip> Nueva referencia </template>
                    </vs-tooltip>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'routes'" id="routesTab">
            <div id="line-container">
                <div id="line-title" class="d-flex flex-wrap">
                    <div class="d-flex flex-column">
                        <h2>Líneas y sublíneas del producto</h2>
                        <span>
                            Las líneas y sublíneas te ayudarán a agrupar y
                            clasificar los productos de manera eficiente. Por
                            ejemplo, puedes utilizar la línea "Escolar" y la
                            sbulínea "Uniformes" para una organización más
                            detallada.
                        </span>
                    </div>
                </div>
                <div class="d-flex flex-wrap">
                    <line-subline-manage
                        v-model="productComponent.subline"
                        :product-id="id"
                        :enabled="enabledWithGlobal"
                    />
                </div>
            </div>
            <div v-if="$enabledEcommerceOptions" id="categories-container">
                <div id="categories-title" class="d-flex flex-wrap">
                    <div class="d-flex flex-column">
                        <h2>Categorías del producto</h2>
                        <span>
                            Las categorías determinan los productos que seran
                            cargados despues de la carga inicial del producto.
                            Los productos pueden agruparse por el personaje
                            principal, por la serie o anime, el colegio del
                            producto o cualquier característica que permita
                            agrupar a productos de diferentes líneas.
                        </span>
                    </div>
                </div>
                <div class="d-flex flex-wrap">
                    <categories-manage
                        v-model="productComponent.categories"
                        :enabled="enabledWithGlobal"
                    >
                        <template slot="action">
                            <vs-button
                                :loading="isLoadingCategories"
                                class="mt-4"
                                @click="updateCategoriesProducts"
                            >
                                Actualizar categorías
                            </vs-button>
                        </template>
                    </categories-manage>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'files'" id="filesTab">
            <div id="line-container">
                <div id="line-title" class="d-flex flex-wrap">
                    <div class="d-flex flex-column">
                        <h2>Archivos adjuntos para el producto</h2>
                        <span>
                            En esta sección puedes asociar archivos a tu
                            producto como guias de tallas, manual de uso o
                            cualquier otro archivo que ayude al cliente a saber
                            más información del producto. Los archivos cargados
                            comercioados en la nube y tendrán libre acceso.
                        </span>
                    </div>
                </div>
                <div>
                    <files-manage
                        :id="`${productComponent.id}`"
                        v-model="productComponent.files"
                        :enabled="enabledWithGlobal"
                        class="mt-4"
                    />
                    <div class="d-flex justify-content-center">
                        <vs-button
                            v-if="enabledWithGlobal"
                            :loading="isLoadingFiles"
                            class="mt-4"
                            @click="updateFilesProduct"
                        >
                            Actualizar archivos
                        </vs-button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'extraOptions'" id="extraOptionsTab" class="">
            <div id="extra-options-container">
                <div id="extra-options-title" class="d-flex flex-wrap">
                    <div class="d-flex flex-column">
                        <h2>Opciones Extra</h2>
                        <span>
                            Agrega opciones extra para que el cliente pueda
                            seleccionar o especificar al realizar la compra de
                            un producto. Estas opciones pueden incluir elementos
                            como cubiertos para comidas, la selección de bebidas
                            en combos, elegir un diseño personalizado para ropa,
                            o añadir un mensaje especial al producto. Cada
                            opción puede ser personalizada para adaptarse a las
                            necesidades del cliente, ofreciendo una experiencia
                            de compra más completa y flexible.
                        </span>
                    </div>
                    <div class="col-lg-10 col-12 mt-5 mx-auto">
                        <product-extra-options-manager
                            v-model="productComponent.extraOptions"
                            :enabled="enabledWithGlobal"
                        />
                    </div>

                    <div class="col-12 my-3">
                        <vs-button
                            v-if="enabledWithGlobal"
                            class="mx-auto"
                            @click="updateExtraOptionsComponent"
                        >
                            Actualizar opciones
                        </vs-button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'delivery'" id="deliveryTab" class="">
            <div id="delivery-container">
                <div id="delivery-title" class="d-flex flex-wrap">
                    <div class="d-flex flex-column">
                        <h2>Configuración logística</h2>
                        <span>
                            Selecciona la configuración logistica para
                            establecer tiempos de entrega y costos adicionales
                            en la compra de un producto.
                        </span>
                    </div>
                    <div class="col-lg-8 col-md-10 col-12 mt-5">
                        <logistic-configuration-selector
                            v-model="productComponent.logisticConfiguration"
                            :enabled="enabledWithGlobal"
                        />
                    </div>

                    <div class="col-12 my-3">
                        <vs-button
                            v-if="enabledWithGlobal"
                            class="mx-auto"
                            @click="updateProductDeliveryComponent"
                        >
                            Actualizar configuración
                        </vs-button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="activeTab === 'seo'" id="seoTab" class="">
            <seo-analytic
                :meta-description="productComponent.shortDescription"
                :url="productComponent.slug"
                :title="productComponent.name"
                :keywords="productComponent.keywords"
                :description="`# ${productComponent.name} 
                ${productComponent.description}`"
            />
        </div>

        <div v-if="activeTab === 'advance'" id="advanceTab" class="">
            <div id="title-routes" class="d-flex flex-wrap">
                <div class="d-flex flex-column">
                    <h2>Avanzado</h2>
                    <span>
                        En esta sección podrás eliminar el producto, ten mucho
                        cuidado.
                    </span>
                </div>
            </div>
            <div class="d-flex justify-content-around mt-5">
                <vs-button
                    v-if="$ability.hasScope('product:Delete')"
                    danger
                    @click="deleteItemComponent"
                >
                    Eliminación
                </vs-button>
                <vs-button
                    v-if="$ability.hasScope('product:Delete')"
                    danger
                    @click="deleteItemDefinitelyComponent"
                >
                    Eliminación definitiva
                </vs-button>
            </div>
        </div>

        <vs-dialog
            v-model="openActiveModal"
            auto-width
            @close="closeActiveModal"
        >
            <template #header>
                <h2>Quieres habilitar el producto ?</h2>
            </template>
            <div class="my-3 mx-2">
                <div class="col-10 mx-auto">
                    <span>
                        Recuerda cargar imágenes para cada referencia habilitada
                        del producto, de esta forma será más atractivo.
                        Selecciona el peso de ordenamiento, el producto con peso
                        1 será la primero en aparecer en el listado.
                    </span>
                </div>

                <vs-input
                    v-model="productComponent.weight"
                    class="col-8 mx-auto mt-4 opacity-false"
                    label="Peso"
                    state="primary"
                    type="number"
                    step="1"
                    min="1"
                />
            </div>
            <template #footer>
                <div class="con-footer d-flex justify-content-center my-2">
                    <vs-button @click="publishComponent">
                        Publicar producto
                    </vs-button>
                </div>
            </template>
        </vs-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { TARGET_GROUPS } from "@/common/constants";
import { keywordsBase } from "@/common/fields/keywords-base";
import {
    shortDescriptionRegex,
    slugRegex,
    titleRegex
} from "@/common/lib/regex";
import { slugify } from "@/common/lib/transformers";

import BrandSelector from "@/components/brands/BrandSelector.vue";
import CategoriesManage from "@/components/categories/CategoriesManage.vue";
import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import LineSublineManage from "@/components/lines/LineSublineManage.vue";
import LogisticConfigurationSelector from "@/components/logistic-configurations/LogisticConfigurationSelector.vue";
import ProductExtraOptionsManager from "@/components/products/ProductExtraOptionsManage.vue";
import ProductTypeSelector from "@/components/products/ProductTypeSelector.vue";
import ReferenceManage from "@/components/references/ReferenceManage.vue";
import ActiveSelector from "@/components/utils/ActiveSelector.vue";
import ArrayStringSelector from "@/components/utils/ArrayStringSelector.vue";
import FeatureManage from "@/components/utils/FeatureManage.vue";
import FilesManage from "@/components/utils/FilesManage.vue";
import KeywordsManage from "@/components/utils/KeywordsManage.vue";
import MarkdownEditor from "@/components/utils/MarkdownEditor.vue";
import OptionsSelector from "@/components/utils/OptionsSelector.vue";
import SeoAnalytic from "@/components/utils/SeoAnalytic.vue";

export default {
    name: "CreateUpdateProduct",
    components: {
        ReloadIcon,
        FilesManage,
        SeoAnalytic,
        BrandSelector,
        FeatureManage,
        ActiveSelector,
        MarkdownEditor,
        OptionsSelector,
        ReferenceManage,
        KeywordsManage,
        CategoriesManage,
        LineSublineManage,
        ArrayStringSelector,
        ProductTypeSelector,
        LogisticConfigurationSelector,
        ProductExtraOptionsManager
    },
    data: () => ({
        id: 0,
        name: "",
        activeTab: "general",
        taxRate: 0,
        isLoading: false,
        loadProduct: false,
        openReferenceId: 0,
        defaultReferenceId: 0,
        enabledUpdate: false,
        loaderInstance: null,
        openActiveModal: false,
        isLoadingCategories: false,
        isLoadingFiles: false,
        $keywordsBase: keywordsBase,
        $targetGroupsCodes: TARGET_GROUPS,
        hasGlobalScope: false,
        introValidators: { slug: false, name: false, shortDescription: false },
        productComponent: {
            id: 0,
            sku: "",
            slug: "",
            name: "",
            type: "article",
            price: 0,
            weight: 1000,
            targetGroups: [],
            tags: [],
            keywords: [],
            createAt: "",
            features: [],
            updatedAt: "",
            active: false,
            priceOffer: 0,
            description: "",
            files: [],
            references: [],
            categories: [],
            extraOptions: [],
            shortDescription: "",
            brand: {
                name: "",
                slug: ""
            },
            logisticConfiguration: {
                name: "",
                slug: ""
            },
            subline: {
                slug: "",
                name: "",
                shortDescription: "",
                line: {
                    slug: "",
                    name: "",
                    shortDescription: ""
                }
            }
        }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        isValidSlug() {
            return slugRegex.test(this.productComponent.slug);
        },
        isValidName() {
            return titleRegex.test(this.productComponent.name);
        },
        isValidShortDescription() {
            return shortDescriptionRegex.test(
                this.productComponent.shortDescription
            );
        },
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        },
        disabledWithGlobal() {
            return !this.enabledUpdate || !this.hasGlobalScope;
        },
        enabledWithGlobal() {
            return this.enabledUpdate && this.hasGlobalScope;
        }
    },
    watch: {
        activeTab(value) {
            let currentUrl = new URL(window.location.href);
            currentUrl.searchParams.set("active", value);
            window.history.replaceState({}, "", currentUrl);
        }
    },
    created() {
        this.$watch(
            () => this.$route,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    location.reload();
                }
            },
            {
                deep: true
            }
        );
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        this.hasGlobalScope = this.$ability.hasScope("product:Update_");
        const tabs = {
            references: "references",
            general: "general",
            routes: "routes",
            delivery: "delivery",
            extraOptions: "extraOptions",
            files: "files"
        };
        if ("id" in this.$route.params) {
            if (!(await verifyPageScope("product:Get", "/app/products")))
                return;
            this.id = Number(this.$route.params.id);
            this.name = "Cargando...";
            await this.getBaseItem();
            if ("active" in this.$route.query) {
                this.activeTab = tabs[this.$route.query.active] || "general";
            }
        } else if ("idClone" in this.$route.params) {
            if (!(await verifyPageScope("product:Create", "/app/products")))
                return;
            this.id = 0;
            this.enabledUpdate = true;
            this.id = Number(this.$route.params.idClone);
            await this.getBaseItem();
            this.productComponent = {
                ...this.productComponent,
                name: `${this.productComponent.name} CLONE`,
                slug: `${this.productComponent.slug}-clone`,
                sku: `${this.productComponent.sku}1`,
                active: false
            };

            this.$swal.fire({
                background: this.backgroundColor,
                title: "Estas clonando un producto",
                text: `Antes de guardar el producto asegurate de cambiar el nombre, sku y slug. Una vez hayas creado el producto pasa por todos los tab y guarda/actualiza toda la información, en especial cada referencia.

Recuerda el código de barras se guarda por referencia 📡.`,
                icon: "info",
                showCloseButton: true
            });
        } else {
            if (!(await verifyPageScope("product:Create", "/app/products")))
                return;
            this.id = 0;
            this.enabledUpdate = true;
            this.loadProduct = true;
        }
    },
    methods: {
        ...mapActions("products", [
            "updateProduct",
            "createProduct",
            "deleteProduct",
            "getProductById",
            "publishProduct",
            "updateProductsFiles",
            "deleteReference",
            "setDefaultReference",
            "updateProductDelivery",
            "updateProductExtraOptions",
            "updateProductsCategories"
        ]),
        async setSlug() {
            this.introValidators.name = true;
            if (
                this.enabledUpdate &&
                this.productComponent.name &&
                !this.productComponent.active
            ) {
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Quieres cambiar el slug ?",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: "#d33ddd",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                });
                if (dialogResult.isConfirmed) {
                    this.productComponent.slug = slugify(
                        this.productComponent.name
                    );
                }
            }
        },
        async deleteItemComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "Seguro deseas eliminar el producto ?",
                text: "Recuerda se va a eliminar tu producto y puedes perder tiempo 💀",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (dialogResult.isConfirmed) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({ type: "circles" });
                try {
                    await this.deleteProduct({
                        id: this.productComponent.id,
                        definitely: false
                    });
                    this.$vs.notification({
                        title: `Producto # ${this.productComponent.id} eliminado`,
                        color: "primary",
                        icon: "🚧"
                    });
                    this.$router.push("/app/products");
                } catch (error) {
                    this.loaderInstance.close();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                } finally {
                    this.loaderInstance.close();
                    this.isLoading = false;
                }
            }
        },
        async deleteItemDefinitelyComponent() {
            const dialogResult = await this.$swal.fire({
                background: this.backgroundColor,
                title: "Seguro ?",
                text: "Recuerda se va a eliminar tu producto definitivamente y puedes perder tiempo 💀",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33ddd",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            });
            if (dialogResult.isConfirmed) {
                this.isLoading = true;
                this.loaderInstance = this.$vs.loading({ type: "circles" });
                try {
                    await this.deleteProduct({
                        id: this.productComponent.id,
                        definitely: true
                    });
                    this.$vs.notification({
                        title: `Producto # ${this.productComponent.id} eliminado`,
                        color: "primary",
                        icon: "🚧"
                    });
                    this.$router.push("/app/products");
                } catch (error) {
                    this.loaderInstance.close();
                    this.$swal.fire({
                        background: this.backgroundColor,
                        title: error.title,
                        text: error.message,
                        icon: error.icon
                    });
                } finally {
                    this.loaderInstance.close();
                    this.isLoading = false;
                }
            }
        },
        async getBaseItem() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                let result = await this.getProductById({
                    id: this.id
                });
                this.name = result.name;
                this.productComponent = { ...result };
                this.applyDefaultReference();
                this.isLoading = false;
                this.loadProduct = true;
                this.loaderInstance.close();
            } catch (error) {
                this.isLoading = false;
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/products");
                }
            }
        },
        async updateFilesProduct() {
            this.isLoadingFiles = true;
            try {
                await this.updateProductsFiles({
                    productId: this.productComponent.id,
                    filesIds: this.productComponent.files.map((item) => ({
                        id: item.id
                    }))
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoadingFiles = false;
            }
        },
        async updateCategoriesProducts() {
            this.isLoadingCategories = true;
            try {
                await this.updateProductsCategories({
                    productId: this.productComponent.id,
                    categoriesSlugs: this.productComponent.categories.map(
                        (item) => ({ slug: item.slug })
                    )
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoadingCategories = false;
            }
        },
        async setDefaultReferenceComponent(referenceId) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                await this.setDefaultReference({
                    referenceId,
                    productId: this.productComponent.id
                });
                this.defaultReferenceId = referenceId;
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateProductComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                await this.updateProduct(this.productComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateExtraOptionsComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                await this.updateProductExtraOptions({
                    id: this.productComponent.id,
                    extraOptions: this.productComponent.extraOptions
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateProductDeliveryComponent() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                await this.updateProductDelivery({
                    id: this.productComponent.id,
                    logisticConfigurationSlug:
                        this.productComponent.logisticConfiguration.slug
                });
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async publishComponent() {
            this.openActiveModal = false;
            if (this.productComponent.active) {
                if (this.productComponent.references.length < 1) {
                    this.productComponent.active = false;
                    return this.$swal.fire({
                        background: this.backgroundColor,
                        title: "Referencias",
                        text: "Debes de tener como mínimo una referencia para activar el producto",
                        icon: "info"
                    });
                }
            }

            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                await this.publishProduct(this.productComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
            } catch (error) {
                this.productComponent.active = !this.productComponent.active;
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async createProductFastComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                const data = await this.createProduct(this.productComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.isLoading = false;
                this.loaderInstance.close();
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: `Producto creado con el ID: ${data.id}`,
                    text: "Deseas ir al detalle del producto registrado o continuar agregando productos ?",
                    icon: "success",
                    showClass: {
                        popup: "animate__animated animate__fadeInDown"
                    },
                    hideClass: {
                        popup: "animate__animated animate__fadeOutUp"
                    },
                    focusConfirm: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a lista de productos",
                    cancelButtonText: "Nuevo producto",
                    allowOutsideClick: false
                });
                if (dialogResult.isConfirmed) {
                    this.productComponent = {
                        ...data,
                        ...this.productComponent
                    };
                    this.id = data.id;
                    this.name = this.productComponent.name;
                    this.$router.push(`/app/products/detail/${this.id}`);
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/products/");
                }
                if (dialogResult.isDismissed) {
                    this.resetItem();
                }
            } catch (error) {
                this.isLoading = false;
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            }
        },
        openSalesProductComponent() {
            if (this.id) {
                window.open(`/app/orders/product/${this.id}`, "_blank").focus();
            }
        },
        openProduction(complement) {
            window
                .open(
                    `${this.$marketplaceUrl}/${this.productComponent.slug}${complement}`,
                    "_blank"
                )
                .focus();
        },
        applyDefaultReference() {
            if (this.productComponent.references.length) {
                let openReferenceId = this.productComponent.references[0].id;
                const seachReference = this.$route.query.reference;
                if (seachReference) {
                    const defaultReferenceId =
                        this.productComponent.references.find(
                            (item) => Number(seachReference) === item.id
                        );
                    if (defaultReferenceId) {
                        openReferenceId = defaultReferenceId.id;
                    }
                } else {
                    const defaultReferenceId =
                        this.productComponent.references.find(
                            (item) => item.isDefault
                        );
                    if (defaultReferenceId) {
                        openReferenceId = defaultReferenceId.id;
                    }
                }
                this.defaultReferenceId = openReferenceId;
                this.changeActiveQuery(openReferenceId);
            }
        },
        changeActiveQuery(referenceId) {
            let currentUrl = new URL(window.location.href);
            if (!referenceId) {
                currentUrl.searchParams.delete("reference");
            } else {
                currentUrl.searchParams.set("reference", referenceId);
            }
            window.history.replaceState({}, "", currentUrl);
            this.openReferenceId = referenceId;
        },
        createNewReference() {
            if (this.productComponent.references.length > 0) {
                this.productComponent.references.push({
                    ...this.productComponent.references[0],
                    stockMerchant: 0,
                    stock: 0,
                    active: false,
                    id: 0,
                    barcode: `${this.productComponent.references[0].barcode}1`
                });
            } else {
                this.productComponent.references.push({
                    active: false,
                    minUnitsForPriceWholesale: 1,
                    stock: 0,
                    color: "blanco",
                    colorRef: "#fff",
                    createdAt: "",
                    updatedAt: "",
                    default: false,
                    id: 0,
                    images: [],
                    price: this.productComponent.price,
                    priceOffer: this.productComponent.priceOffer,
                    productId: this.productComponent.id,
                    size: "unico",
                    priceMerchant: this.productComponent.price,
                    priceOfferMerchant: this.productComponent.priceOffer
                });
            }
            this.openReferenceId = 0;
        },
        createdReference(reference) {
            if (this.$route.fullPath.includes("new")) {
                this.defaultReferenceId = reference.id;
            }
            this.productComponent.references.pop();
            this.productComponent.references.push(reference);
        },
        changeReference(reference) {
            const index = this.productComponent.references.findIndex(
                (item) => item.id === reference.id
            );
            let references = [...this.productComponent.references];
            references[index] = {
                ...reference
            };
            this.productComponent.references = [...references];
        },
        async removeReference({ id }) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "circles" });
            try {
                await this.deleteReference({
                    referenceId: id
                });
                this.$vs.notification({
                    title: `Referencia # ${id} eliminada`,
                    color: "primary",
                    icon: "🚧"
                });
                this.productComponent.references =
                    this.productComponent.references.filter(
                        (item) => item.id !== id
                    );
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        validateData() {
            this.introValidators = {
                slug: true,
                name: true,
                shortDescription: true
            };
            const toComplete = [];
            if (!this.isValidSlug) {
                toComplete.push("Slug");
            }

            if (!this.isValidName) {
                toComplete.push("Nombre");
            }

            if (!this.productComponent.brand.slug) {
                toComplete.push("Marca");
            }

            if (!this.isValidShortDescription) {
                toComplete.push("Descripción");
            }

            if (this.productComponent.description === "") {
                toComplete.push("Contenido");
            }

            if (this.productComponent.type === "") {
                toComplete.push("Tipo");
            }

            if (this.productComponent.keywords === "") {
                toComplete.push("Palabras clave");
            }

            if (toComplete.length) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Producto invalido",
                    text: `Revisa por favor que todo este completo. Valida los siguientes campos: ${toComplete.join(
                        ", "
                    )}`,
                    icon: "info"
                });
                return false;
            }
            return true;
        },
        validateTogglePublish() {
            if (!this.productComponent.active) {
                return this.publishComponent();
            }
            this.openActiveModal = true;
        },
        closeActiveModal() {
            this.productComponent.active = false;
        },
        resetItem() {
            this.introValidators = { slug: false };
            this.productComponent = {
                id: 0,
                sku: "",
                slug: "",
                name: "",
                type: "",
                price: 0,
                weight: 1000,
                targetGroups: [],
                tags: [],
                keywords: [],
                createAt: "",
                features: [],
                updatedAt: "",
                active: false,
                priceOffer: 0,
                description: "",
                files: [],
                references: [],
                categories: [],
                shortDescription: "",
                brand: {
                    name: "",
                    slug: ""
                },
                subline: {
                    slug: "",
                    name: "",
                    shortDescription: "",
                    line: {
                        id: "",
                        name: "",
                        shortDescription: ""
                    }
                }
            };
        }
    }
};
</script>

<style>
.vs-navbar-content {
    z-index: 1000 !important;
}
</style>
